import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import tw, { styled } from "twin.macro";
import ReactPlayer from "react-player/vimeo";
import Button from "../PageComponents/Button";
import FallBack from "../../images/hero_fallback.png";
import colors from "../../utils/globals.scss";
import { Keyframes, Frame } from "react-keyframes";

const Hero = styled.section`
  ${tw`
    relative flex justify-center flex-col bg-center bg-no-repeat bg-cover
  `}
  width: 100%;
  height: 85vh;
  background-image: ${props =>
    props.background ? `url(${props.background})` : `url(${FallBack})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
  }

  @media (min-width: 640px) {
    height: 100vh;
  }

  @media (min-aspect-ratio: 16/9) {
    iframe {
      /* height = 100 * (9 / 16) = 56.25 */
      height: 56.25vw !important;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    iframe {
      /* width = 100 / (9 / 16) = 177.777777 */
      width: 177.78vh !important;
    }
  }
`;

const HeroImage = styled.img`
  opacity: 0;
  width: 100%;
  max-width: 100%;
`;

const TextContainer = styled.aside`
  ${tw`
    absolute top-0 left-0 bottom-0 right-0 flex justify-center flex-col text-center items-center
  `}
`;

const Spacer = styled.h1`
  font-size: 3rem;
  line-height: 1;
  color: transparent;

  // @media (min-width: 640px) {
  //   font-size: 4rem;
  // }

  // @media (min-width: 768px) {
  //   font-size: 100px;
  //   letter-spacing: -3.63px;
  //   margin-bottom: 45px;
  // }

  // @media (min-width: 1024px) {
  //   font-size: 150px;
  //   font-size: 9.5rem;
  //   letter-spacing: -6.85px;
  //   margin-bottom: 35px;
  // }
`;

const HeroTitle = styled.h1`
  ${tw`
    text-white font-bold mb-5
  `}
  font-size: 3rem;
  line-height: 1;
  letter-spacing: -0.48px;
  position: relative;

  &.first {
    .wipe {
      animation-delay: 1s;
    }
    span {
      animation-delay: 1s;
    }
  }

  span {
    opacity: 0;
    animation-name: fadein;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;

    z-index: 0;
  }

  @media (min-width: 640px) {
    font-size: 4rem;
  }

  @media (min-width: 768px) {
    font-size: 5rem;
    letter-spacing: -3.63px;
    margin-bottom: 2rem;
  }

  @media (min-width: 1024px) {
    font-size: 7.5rem;
    letter-spacing: -6.85px;
  }

  @keyframes fadein {
    0 {
      opacity: 0;
    }

    49% {
      opacity: 0;
    }

    50% {
      opacity: 100%;
    }

    100% {
      opacity: 100%;
    }
  }
`;

const Wipe = styled.div`
  background-color: var(--color-magenta);
  position: absolute;
  left: -0.5rem;
  right: auto;
  height: 120%;
  width: 0;
  top: 50%;
  transform: translateY(-40%);
  animation-name: showbar;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;

  z-index: 1;

  @keyframes showbar {
    0 {
      width: 0;
    }

    49% {
      width: 100%;
      left: -0.5rem;
      right: auto;
    }

    50% {
      width: 100%;
      left: auto;
      right: -0.5rem;
    }

    100% {
      left: auto;
      right: -0.5rem;
      width: 0%;
    }
  }
`;

const HeroSubTitle = styled.aside`
  ${tw`
    text-white font-bold mt-0 mx-auto max-w-xs sm:max-w-xl lg:max-w-3xl
  `}

  p {
    font-size: 18px;
    line-height: 1.25;
    letter-spacing: -0.17px;
    margin-bottom: 20px;
  }

  @media (min-width: 640px) {
    p {
      font-size: 28px;
      letter-spacing: -0.27px;
      margin-bottom: 35px;
    }
  }

  @media (min-width: 1024px) {
    ${tw`
    text-white font-bold mt-0 mx-auto mb-12 text-lg
  `}
    p {
      font-size: 2rem;
      letter-spacing: -0.37px;
      max-width: 40ch;
    }
  }
`;

const HeroAnimation = ({ headings }) => {
  const durations = [4000, 0, 4000, 0, 4000];
  const totalDuration = durations.reduce((prev, curr) => (curr += prev));

  // We're going to force the animation to loop by setting the Keyframes
  // component's key prop to a new value every time the animation concludes
  const [animationCount, setAnimationCount] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setAnimationCount(animationCount + 1);
    }, totalDuration);
  });

  return (
    <Keyframes key={animationCount}>
      <Frame duration={durations[0]}>
        <HeroTitle className={`${animationCount === 0 ? "first" : ""}`}>
          <Wipe className="wipe" />
          <span>{headings[0].title}</span>
        </HeroTitle>
      </Frame>
      <Frame duration={durations[1]}>
        <Spacer>{headings[0].title}</Spacer>
      </Frame>
      <Frame duration={durations[2]}>
        <HeroTitle>
          <Wipe className="wipe" />
          <span>{headings[1].title}</span>
        </HeroTitle>
      </Frame>
      <Frame duration={durations[3]}>
        <Spacer>{headings[1].title}</Spacer>
      </Frame>
      <Frame duration={durations[4]}>
        <HeroTitle>
          <Wipe className="wipe" />
          <span>{headings[2].title}</span>
        </HeroTitle>
      </Frame>
    </Keyframes>
  );
};

const HeroComponent = ({ type, assetUrl, poster, headings, content }) => {
  const media =
    type === "photo" ? (
      <HeroImage src={assetUrl} alt="Hero Photo" />
    ) : (
      <ReactPlayer
        url={assetUrl || "https://vimeo.com/447558445/ff35d5855f"}
        loop={true}
        muted={true}
        controls={false}
        playsinline={true}
        playing={true}
        className="background"
      />
    );

  return (
    <Hero type={type} background={poster}>
      {media}
      <TextContainer>
        <div className="pt-10 lg:pt-24">
          <HeroAnimation headings={headings} />
          <HeroSubTitle dangerouslySetInnerHTML={{ __html: content }} />
          <Button style={"light"}>
            <a href="https://eachstephome.donorsupport.co/-/XYWQRRQB">Donate Now</a>
          </Button>
        </div>
      </TextContainer>
    </Hero>
  );
};

export default HeroComponent;
