import React from "react";
import tw, { styled } from "twin.macro";
import MurderStats from "./MurderStats";

const StatsNewsletterWrapper = styled.section`
  ${tw`
    flex flex-col relative
  `}
  background-color: var(--color-offWhite);

  @media (min-width: 768px) {
    ${tw`
    flex flex-row flex-wrap items-end
  `}
  }

  @media (min-width: 1200px) {
    ${tw`
    flex-no-wrap justify-between
    `}
    align-items: initial;
  }
`;

const StatsAndNewsletterComponent = ({ murder_stats }) => {
  return (
    <StatsNewsletterWrapper>
      <MurderStats {...murder_stats} />
    </StatsNewsletterWrapper>
  );
};

export default StatsAndNewsletterComponent;
