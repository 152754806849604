import React from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from "gatsby";
import { styled } from "twin.macro";
import Hero from "../components/PageSections/Hero";
import StatsNewsletterWrapper from "../components/PageSections/StatsNewsletterWrapper";
import Successes from "../components/PageSections/Successes";
// import Carousel from "../components/PageSections/Carousel";
import FullVideo from "../components/PageSections/FullVideo";
import HomeGetInvolved from "../components/PageSections/HomeGetInvolved";
import OurParnters from "../components/PageSections/OurPartners";
import ForComponent from "../components/PageSections/ForComponent";
import Newsletter from "../components/PageSections/Newsletter";
import WhatWeDo from "../components/PageSections/WhatWeDo";

const Home = styled.main``;

const ForHomeFamilies = styled.section`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    max-width: 1440px;
    margin: 0 auto;
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "home" } }) {
        nodes {
          slug
          status
          template
          title
          type
          wordpress_id
          menu_order
          link
          content
          date
          id
          path
          acf {
            alert {
              copy
            }
            home_hero_video {
              video
              poster {
                source_url
              }
              titles {
                title
              }
            }
            successes {
              title
              link {
                title
                url
                target
              }
              copy
            }
            what_we_do {
              title
              actions {
                copy
                title
                image {
                  source_url
                }
                link {
                  url
                  title
                  target
                }
              }
            }
            get_involved {
              actions {
                copy
                link {
                  title
                  url
                  target
                }
                title
              }
            }
            our_partners {
              client_name
              url
              logo {
                source_url
              }
            }
            full_video {
              title
              video
              poster {
                source_url
              }
            }
            for_families {
              copy
              link {
                title
                url
                target
              }
              title
              image {
                source_url
              }
            }
            for_advocates {
              copy
              link {
                title
                url
                target
              }
              title
              image {
                source_url
              }
            }
            murder_stats {
              title
              source
              stats {
                number
                country_name
              }
              bottom_text
            }
          }
          yoast_meta {
            yoast_wpseo_social_defaults {
              og_frontpage_image {
                source_url
              }
            }
            yoast_wpseo_metadesc
            yoast_wpseo_facebook_title
            yoast_wpseo_facebook_image {
              source_url
            }
            yoast_wpseo_facebook_description
            yoast_wpseo_twitter_title
            yoast_wpseo_twitter_image {
              source_url
            }
            yoast_wpseo_twitter_description
          }
        }
      }
    }
  `);

  const home = data.allWordpressPage.nodes[0];
  const { yoast_meta, title } = data.allWordpressPage.nodes[0];

  const {
    yoast_wpseo_metadesc: seo_desc,
    yoast_wpseo_facebook_title: seo_fb_title,
    yoast_wpseo_facebook_description: seo_fb_desc,
    yoast_wpseo_twitter_title: seo_tw_title,
    yoast_wpseo_twitter_description: seo_tw_desc
  } = yoast_meta;

  const seo_image =
    yoast_meta.yoast_wpseo_social_defaults?.og_frontpage_image?.source_url ||
    "";
  const seo_fb_image =
    yoast_meta.yoast_wpseo_facebook_image?.source_url || seo_image;
  const seo_tw_image =
    yoast_meta.yoast_wpseo_twitter_image?.source_url || seo_image;

  const {
    home_hero_video,
    for_advocates,
    for_families,
    full_video,
    get_involved,
    our_partners,
    murder_stats,
    successes,
    what_we_do,
    alert
  } = home?.acf;
  // destructure all acf fields

  return (
    <Layout alert={alert}>
      <SEO
        title={title}
        description={seo_desc}
        image={seo_fb_image}
        metaTitleFacebook={seo_fb_title}
        metaImageFacebook={seo_fb_image}
        metaDescFacebook={seo_fb_desc}
        metaTitleTwitter={seo_tw_title}
        metaImageTwitter={seo_tw_image}
        metaDescTwitter={seo_tw_desc}
      />
      <Home>
        <Hero
          type={"video"}
          assetUrl={home_hero_video?.video}
          poster={
            home_hero_video?.poster?.source_url || home_hero_video?.poster?.url
          }
          headings={home_hero_video?.titles}
          content={home?.content}
        />
        <FullVideo
          title={full_video?.title}
          video={full_video?.video}
          poster={full_video?.poster?.source_url}
        />

        <WhatWeDo data={what_we_do} />

        <ForHomeFamilies>
          <ForComponent
            title={for_families?.title}
            copy={for_families?.copy}
            link={for_families?.link}
            image={for_families?.image}
          />
          <ForComponent
            title={for_advocates?.title}
            copy={for_advocates?.copy}
            link={for_advocates?.link}
            image={for_advocates?.image}
          />
        </ForHomeFamilies>
        <Successes
          heading={successes?.title}
          subtitle={successes?.copy}
          link={successes?.link}
        />

        <HomeGetInvolved get_involved={get_involved} />
        <StatsNewsletterWrapper murder_stats={murder_stats} />
        <Newsletter />
        <OurParnters partners={our_partners} />
      </Home>
    </Layout>
  );
};

export default IndexPage;
