import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Button from "../PageComponents/Button";

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-magenta);
  padding: 2rem 0;

  @media (min-width: 1024px) {
    min-height: 600px;
  }
`;

const InnerContent = styled.aside`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const GetInvolvedTitles = styled.ul`
  background-color: #fff;
  padding: 20px 0;
  text-align: center;
  font-weight: 600;
  filter: drop-shadow(0 5px 12px rgba(0, 0, 0, 0.2));

  @media (min-width: 768px) {
    padding: 40px 0;
  }

  @media (min-width: 1024px) {
    padding: 40px 56px;
    text-align: left;
    min-width: 375px;
    width: calc(50% - 25px);
  }

  @media (min-width: 1200px) {
    min-width: 400px;
    max-width: 500px;
    height: 0%;
  }
`;

const SectionTitle = styled.h4`
  // font-size: 2rem;
  // line-height: 38px;
  // letter-spacing: -0.81px;
  // margin-bottom: 10px;

  // @media (min-width: 768px) {
  //   font-size: 40px;
  //   line-height: 48px;
  //   letter-spacing: -1.01px;
  //   margin-bottom: 20px;
  // }

  // @media (min-width: 1024px) {
  //   font-size: 43px;
  //   line-height: 52px;
  //   letter-spacing: -1.09px;
  // }
`;

const ClickableTitle = styled.li`
  font-size: 1rem;
  line-height: 19px;
  letter-spacing: -0.4px;
  color: var(--color-grey);
  width: fit-content;
  margin: 0 auto;
  padding-bottom: 2px;
  border-bottom: 1.5px solid transparent;
  margin-bottom: 10px;

  &.active {
    color: var(--color-magenta);
    border-bottom: 1.5px solid var(--color-magenta);
  }

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.56px;
    border-bottom: 2px solid transparent;
    padding-bottom: 2px;

    &.active {
      border-bottom: 2px solid var(--color-magenta);
    }
  }

  @media (min-width: 1024px) {
    font-size: 27px;
    line-height: 32px;
    letter-spacing: -0.68px;
    padding-bottom: 0;
    margin: 0 0 10px;
    cursor: pointer;
    transition: opacity 0.25s ease;

    &:hover {
      opacity: 0.6;
    }
  }
`;

const SectionInfo = styled.article`
  padding: 30px 0 0;
  text-align: center;
  color: #ffffff;

  transition: opacity 0.25s ease;

  &.transitioning {
    opacity: 0;
  }

  @media (min-width: 768px) {
    padding: 60px 0;
  }

  @media (min-width: 1024px) {
    width: 50%;
    padding: 45px 0;
    text-align: left;
  }
`;

const ActionTitle = styled.h5`
  // font-size: 18px;
  // font-weight: 600;
  // line-height: 24px;
  // letter-spacing: -0.17px;
  // margin-bottom: 5px;

  // @media (min-width: 768px) {
  //   font-size: 28px;
  //   line-height: 34px;
  //   letter-spacing: -0.71px;
  //   margin-bottom: 10px;
  // }

  // @media (min-width: 1024px) {
  //   font-size: 38px;
  //   line-height: 48px;
  //   letter-spacing: -0.37px;
  // }
`;

const ActionDescription = styled.p`
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.27px;
  max-width: 32ch;
  margin: 0 auto 20px;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 29px;
    letter-spacing: -0.36px;
    max-width: 46ch;
    margin: 0 auto 36px;
  }

  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.41px;
    margin: 0 0 45px;
  }
`;

const HomeGetInvolved = ({ get_involved }) => {
  const [sections] = useState(get_involved?.actions);
  const [currentSection, setCurrentSection] = useState(
    get_involved?.actions[0]
  );

  const setFade = bool => {
    // adding & removing opacity during the transition
    const slideContent = document.querySelector("#home-get-involved");
    if (bool) {
      slideContent.classList.add("transitioning");
    } else {
      setTimeout(() => {
        slideContent.classList.remove("transitioning");
      }, 500);
    }
  };

  const handleClick = event => {
    setFade(true);
    const clickedTitle = event.target.textContent;
    const newCurrentSection = sections.find(sec => sec.title === clickedTitle);
    // only sets new section if there's a valid section that matches the title
    newCurrentSection &&
      setTimeout(() => {
        setCurrentSection(newCurrentSection);
      }, 500);
    setFade(false);
  };

  return (
    <Container>
      <div className="container">
        <InnerContent>
          <GetInvolvedTitles>
            <SectionTitle>Get Involved</SectionTitle>

            {sections.length > 0 && (
              <>
                {sections.map((item, index) => (
                  <ClickableTitle
                    onClick={handleClick}
                    key={index}
                    className={
                      currentSection?.title === item?.title ? "active" : ""
                    }
                  >
                    {item?.title}
                  </ClickableTitle>
                ))}
              </>
            )}
          </GetInvolvedTitles>

          {currentSection && (
            <SectionInfo id="home-get-involved">
              <ActionTitle>{currentSection?.title}</ActionTitle>
              <ActionDescription>{currentSection?.copy}</ActionDescription>

              <Button style={"light"}>
                {currentSection.title.toLowerCase() === "give a donation" && (
                  <Link to="https://everylastone.donorsupport.co/-/XYWQRRQB">
                    Donate
                  </Link>
                )}
                {currentSection.title.toLowerCase() === "speak up" && (
                  <Link to="/get-involved#speak">Speak Up</Link>
                )}
                {currentSection.title.toLowerCase() === "volunteer your time" && (
                  <Link to="/get-involved#volunteer">Volunteer</Link>
                )}
                {currentSection.title.toLowerCase() === "sponsor a child" && (
                  <Link to="/get-involved#sponsor">Learn More</Link>
                )}
              </Button>
            </SectionInfo>
          )}
        </InnerContent>
      </div>
    </Container>
  );
};

export default HomeGetInvolved;
