import React from "react";
import tw, { styled } from "twin.macro";
import { Link } from "gatsby";
import Button from "../PageComponents/Button";

const For = styled.section`
  text-align: center;
  width: 100%;
  padding: 60px 0;

  &:first-of-type {
    border-bottom: 1px solid rgba(124, 135, 149, 0.3);
  }

  @media (min-width: 768px) {
    padding: 90px 0;
  }

  @media (min-width: 1024px) {
    &:first-of-type {
      border-bottom: 0;
      border-right: 1px solid rgba(124, 135, 149, 0.3);
    }
    width: 50%;
    padding: 120px 0;
  }
`;

const Title = styled.h3`
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.81px;
  margin: 5px 0;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 64px;
    line-height: 77px;
    letter-spacing: -1.62px;
    margin: 10px 0;
  }
`;

const Copy = styled.p`
  ${tw`leading-relaxed max-w-sm mx-auto md:max-w-lg mt-4`}
`;

const ForComponent = ({ copy, link, title, image }) => {
  return (
    <For>
      <div className="container">
        <img className="block max-w-full w-48 mx-auto" src={image.source_url} alt={title} />
        <Title>{title}</Title>
        <Copy>{copy}</Copy>
        <div className="mt-5">
          <Link to={`${link.url}/`}>
            <Button style="dark">{link?.title ? link?.title : "Read More"}</Button>
          </Link>
        </div>
      </div>
    </For>
  );
};

export default ForComponent;
