import React from "react";
import tw, { styled } from "twin.macro";
import { Link } from "gatsby";
import Button from "../PageComponents/Button";

const SuccessWrapper = styled.section`
  ${tw`bg-darkBlue relative flex justify-center flex-col bg-center bg-no-repeat bg-cover py-24 lg:py-32`}
`;

const BigTitle = styled.h2`
  ${tw`
    text-white text-center text-5xl md:text-6xl
  `}

  @media (min-width: 1024px) {
    font-size: 4.5rem;
  }

  @media (min-width: 1280px) {
    font-size: 5rem;
  }
`;

const SubTitle = styled.p`
  ${tw`text-white text-center leading-relaxed max-w-sm mx-auto md:max-w-lg lg:max-w-3xl mt-4 lg:mt-6`}
`;

const SuccessesComponent = ({ heading, subtitle, link }) => {
  // links should be entered as just the slug in Wordpress
  // if there's an 'https://' or 'http://` present, let's parse it as the destination
  const parseHttp =
    link?.url.includes("http//") || link?.url.includes("https//");
  let destination = parseHttp ? link.url.split("//")[1] : link.url;

  return (
    <SuccessWrapper>
      <div className="container">
        <BigTitle>{heading}</BigTitle>
        <SubTitle>{subtitle}</SubTitle>
        <div className="mt-5 flex justify-center lg:mt-12">
          <Link to={destination ? destination : "get-involved"}>
            {/* setting 'get-involved' as the fallback */}
            <Button style={"light"}>Read More</Button>
          </Link>
        </div>
      </div>
    </SuccessWrapper>
  );
};

export default SuccessesComponent;
