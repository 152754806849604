import React from "react";
import tw, { styled } from "twin.macro";

const StatsWrapper = styled.article`
  background-color: var(--color-offWhite);

  ${tw`flex flex-wrap text-center py-12 px-4 sm:py-16 `}

  @media (min-width: 640px) {
    justify-content: space-between;
    order: 2;
  }

  @media (min-width: 1200px) {
    margin: 0 auto;
    order: 1;
  }
`;

const TitleSection = styled.section`
  width: 100%;

  h4 {
    ${tw`tracking-tight text-3xl sm:text-4xl`}
  }
`;

const Source = styled.p`
  font-family: attribute-mono, monospace;
  color: var(--color-grey);
  ${tw`text-base uppercase max-w-xs mx-auto leading-tight lg:max-w-full xl:text-lg`}
`;

const Stat = styled.div`
  padding: 10px;
  width: 100%;

  h2 {
    font-size: 7rem;

    @media (min-width: 640px) {
      font-size: 6rem;
      margin: 0;
    }

    @media (min-width: 1024px) {
      font-size: 7rem;
    }

    @media (min-width: 1280px) {
      font-size: 8rem;
    }
  }
`;

const Hash = styled.sup`
  position: relative;
  top: -1rem;
  color: var(--color-magenta);
  font-weight: 600;
  vertical-align: revert;

  font-size: 2rem;
  line-height: 1;
`;

const Country = styled.p`
  color: var(--color-magenta);
  font-weight: 600;

  font-size: 36px;
  line-height: 43px;
  letter-spacing: -1.46px;
`;

const BottomText = styled.section`
  ${tw`font-bold max-w-sm mx-auto lg:max-w-full`}

  strong {
    color: var(--color-offWhite);
    background-color: var(--color-magenta);
    padding: 0 2.5px;
  }

  p {
    ${tw`text-2xl leading-tight`}
    letter-spacing: -0.18px;
  }

  @media (min-width: 768px) {
    p {
      line-height: 28px;
      letter-spacing: -0.56px;
    }
  }
`;

const MurderStats = ({ title, source, bottom_text, stats }) => {
  return (
    <StatsWrapper>
      <TitleSection>
        <h4>{title}</h4>
        <Source>{source}</Source>
      </TitleSection>

      <div className="w-full py-5 sm:flex sm:py-10">
        {stats.map((stat, index) => (
          <Stat key={index}>
            <h2 className="text-magenta leading-none">
              <Hash>#</Hash>
              {stat.number}
            </h2>
            <h4 className="text-magenta tracking-tight text-3xl lg:text-4xl">{stat.country_name}</h4>
          </Stat>
        ))}
      </div>

      <BottomText dangerouslySetInnerHTML={{ __html: bottom_text }} />
    </StatsWrapper>
  );
};
export default MurderStats;
